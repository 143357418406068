import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import Context from '../../utils/context';
import SignIn from '../sign_in'
import { autofocusAll } from '../../helpers'
import { createConversation } from './../../api/conversations';
import './index.css'
import { successToast } from '../ui_kit/utilities/success_toast';
export default function UsersSignIn({
}) {
  let history = useHistory();
  useEffect(() => {
    autofocusAll();
  }, []);

  useEffect(() => {
    const showToast = window.localStorage.getItem('showDeactivationToast');
    if (showToast === 'true') {
      successToast('Your account has been deactivated. We hope to see you back soon!');
      window.localStorage.removeItem('showDeactivationToast');
    }
  }, []);

  const location = useLocation();
  let returnTo = '';
  let searchParams = '';

  if (history.location.state) {
    searchParams = new URLSearchParams(history.location.state);
    returnTo = history.location.state
    if (searchParams.get('return_to')) {
      returnTo = searchParams.get('return_to');
    }
  }
  else {
    searchParams = new URLSearchParams(location.search);
    if (searchParams.get('return_to')) {
      returnTo = searchParams.get('return_to');
    }
  }
  return (<>
    <SignIn
      gtm={`User`}
      onSuccess={(user) => {
        if (user.current_profile_type === 'PatientPartner' && user.sign_in_count === 1) {
          const url = `/member/change-password${returnTo === '' ? '' : `?return_to=${encodeURIComponent(returnTo)}`}`
          history.push(url);
        }
        else {
          if (returnTo === '') {
            if (user.origin === 'Paid Beta Doctor Connect Questionnaire' && user.stripe_customer_id !== null){
              history.push(`/doctor-connect/acl-reconstruction/${user.beta_doctor_connect_contact_uuid}/confirmed`)
            }
            else if (user.origin === 'Paid Beta Doctor Connect Questionnaire' && user.stripe_customer_id == null){
              history.push(`/doctor-connect/acl-reconstruction/${user.beta_doctor_connect_contact_uuid}/name`)
            }
            else if (user.current_profile_type === 'SurgeonAdministrativeAssistant') {
              if (user.is_nybg || user.is_reactiv8) {
                history.push('/provider/mentor-connect')
              }
              else if (user.is_hologic || user.is_vonjo || user.is_xcopri || user.is_morehouse) {
                history.push('/provider/insights');
              } else {
                history.push('/provider/patients');
              }
            } else if (user.current_profile_type === 'Patient'){
              history.push('/member/home')
            } else if (user.current_profile_type === 'PatientPartner'){
              if (user.is_nybg || user.is_reactiv8) {
                history.push('/member/conversations');
              }
              else {
                history.push('/member/discussions')
              }
            } else if (user.newsfeed_test_user) {
              if (user.current_profile.procedures.length === 0) {
                if (user.origin === 'Surgeon Match Questionnaire') {
                  history.push('/member/join/surgeon-match');
                }
                else {
                  history.push('/member/join/support/procedures');
                }
              }
              else {
                history.push('/member/home');
              }
            }
            else {
              history.push('/member/home');
            }
          }
          else if (searchParams !== '' && (searchParams.get('profile_id') && searchParams.get('profile_type'))){
            createConversation({ profile_id: searchParams.get('profile_id'), profile_type: searchParams.get('profile_type'), add_procedure: true }, (conversation) => {
              history.push(`/member/conversations/${conversation.uuid}`)
            })
          }
          else {
            history.push(returnTo);
          }
        }
      }}
    />
  </>);
}

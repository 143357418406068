import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../style.module.css";
import styles1 from "../../posts/createpost.module.css";
import { useClickAway } from "react-use";
import { validateEmail, validatePhone, validateText } from "../../../helpers";
import ErrorMessage from "../../ui_kit/utilities/error_message";
import { successToast } from "../../ui_kit/utilities/success_toast";
import ChangePassword from "../change_password";
import RequestButton from "../../ui_kit/utilities/request_button";
import Context from "../../../utils/context";
import PasswordInput from "../../ui_kit/utilities/password_input";

export default function Account({ medium = true, isMobile }) {
  const [clickModal, setClickModal] = useState(false);
  const ref = useRef(null);
  const context = useContext(Context);
  const [user, setUser] = useState(context.auth.getCurrentUser());
  const [firstName, setFirstName] = useState(user.first_name || "");
  const [lastName, setLastName] = useState(user.last_name || "");
  const [email, setEmail] = useState(user.email || "");
  const [phone, setPhone] = useState(user.phone || "");
  const checkedEmail = validateEmail(email);
  const checkedPhone = validatePhone(phone);
  const checkedFirstName = validateText(firstName);
  const checkedLastName = validateText(lastName);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const validateOnBlur = () => {
    if (email !== "" && !checkedEmail) {
      setEmailError("Enter a valid email");
    } else {
      setEmailError("");
    }
    if (phone !== "" && !checkedPhone) {
      setPhoneError("Enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  useClickAway(ref, () => {
    setClickModal(false);
  });

  useEffect(() => {
    if (showPasswordField && currentPassword !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(!(checkedEmail && checkedPhone && checkedFirstName && checkedLastName && !showPasswordField));
    }

  }, [checkedEmail, checkedPhone, checkedFirstName, checkedLastName, currentPassword, showPasswordField]);

  useEffect(() => {
    if (email !== user.email && checkedEmail) {
      setShowPasswordField(true);
    } else {
      setShowPasswordField(false);
    }
  }, [email, checkedEmail]);

  const onClick = (afterAction) => {
    let params = {};
    if (firstName !== "" && firstName !== user.first_name) {
      params.first_name = firstName;
    }
    if (lastName !== "" && lastName !== user.last_name) {
      params.last_name = lastName;
    }
    if (email !== "" && email !== user.email) {
      params.email = email;
    }
    if (phone !== "" && phone !== user.phone) {
      params.phone = phone;
    }
    if (currentPassword !== "" && showPasswordField) {
      params.current_password = currentPassword;
    }

    context.auth.userUpdate(
      params,
      (result) => {
        successToast("Your changes have been saved.");
        setUser(result)
      },
      (errors) => {
        setError(errors.join(","));
      }
    );
      afterAction();
  };

  return (
    <div className={`container-fluid ${styles.account_cont}`}>
      <div className="row justify-content-center">
        <div className="col">
          <div className="container">
            <div className="row">
              <h3 className={styles.header}>Account & login</h3>
            </div>
            <div className="row">
              <div
                className={`col-6 material-input ${medium ? "medium" : ""} `}
              >
                <p className={styles.label}>First name</p>
                <input
                  className={`autofocus ${styles.no_trans}`}
                  type="text"
                  value={firstName}
                  required
                  placeholder={user.first_name}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
                <span class="highlight"></span>
              </div>
              <div
                className={`col-6 material-input ${medium ? "medium" : ""} `}
              >
                <p className={styles.label}>Last name</p>
                <input
                  className="autofocus"
                  type="text"
                  value={lastName}
                  name="lastName"
                  required
                  placeholder={user.last_name}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
                <span class="highlight"></span>
              </div>
            </div>
            <div className="row">
              <div
                className={`${isMobile ? "col-12" : "col-9"} material-input ${
                  medium ? "medium" : ""
                } `}
              >
                <p className={styles.label}>Phone number</p>
                <input
                  className="autofocus"
                  type="text"
                  value={phone}
                  required
                  placeholder={user.phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  onBlur={validateOnBlur}
                />
                <span class="highlight"></span>
              </div>
            </div>
            {phoneError && <ErrorMessage error={phoneError} />}
            <div className="row">
              <div
                className={`${isMobile ? "col-12" : "col-9"} material-input ${
                  medium ? "medium" : ""
                } `}
              >
                <p className={styles.label}>Email</p>
                <input
                  className="autofocus"
                  type="text"
                  value={email}
                  required
                  placeholder={user.email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={validateOnBlur}
                />
                <span class="highlight"></span>
              </div>
            </div>
            {emailError && <ErrorMessage error={emailError} />}
            {showPasswordField &&
              <div className="row">
                <div
                  className={`${isMobile ? "col-12" : "col-9"} material-input ${
                    medium ? "medium" : ""
                  } `}
                >
                  <p className={styles.label}>Current password</p>
                  <PasswordInput
                    password={currentPassword}
                    setPassword={setCurrentPassword}
                    label="Current password"
                    medium={true}
                    signup={true}
                  />
                  <span class="highlight"></span>
                </div>
              </div>
            }
            <div className="row marg-bottom-20 marg-top-20">
              <p className={`${styles.header}`}>Password</p>
            </div>
              <div className="row">
                <p
                  onClick={() => setClickModal(!clickModal)}
                  className={`text-link no-arrow ${styles1.create_btn}`}
                  style={{paddingLeft: "15px"}}
                  data-pendo-tag="settings - change password"
                >
                  Change password
                </p>
              </div>
              <div className={`row ${styles1.cta_wrap}`} style={{display: "flex", marginBottom: "15px"}}>
                <RequestButton
                  disabled={isDisabled}
                  request={onClick}
                  classnames={`${styles1.create_btn} submit`}
                  style={{float: "right"}}
                  cta="Save"
                  pendoTag="account - save button"
                />
              </div>
            </div>
          </div>
        {clickModal && <ChangePassword setClickModal={setClickModal} />}
      </div>
    </div>
  );
}

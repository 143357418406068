import React, { useState, useEffect, useReducer } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  useLocation
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/footer";
import Home from "./containers/home";
import About from "./containers/about";
import Process from "./containers/process";
// import GetStarted from './containers/get_started';
import GetStartedV2 from "./containers/get_started_v2";
import GetStartedPatientPartnerMatch from "./containers/get_started_v2/patient_partner_match";
import GetStartedSurgeonMatch from "./containers/get_started_v2/surgeon_match";
import WeightLoss from "./containers/weight_loss";
import JointReplacement from "./components/joint_replacement";
import Partners from "./containers/partners";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import ReviewGuidelines from "./components/review_guidelines";
import PatientPartnerAgreement from "./components/patient_partner_agreement";
import PatientPartnerSurvey from "./components/partners/patient_partner_survey";
import SurgeonProfile from "./components/profile/surgeon_profile";
import Surgeon from "./components/surgeon";
import SurgeonRequestAppointment from "./components/surgeon/request_appointment";
import SurgeonReferredMentorMatch from "./components/surgeon/referred_mentor_match";
import Surgeons from "./containers/surgeons";
import SurgeonPartners from "./containers/surgeon_partners";
import WeightLossSurgeonMatch from "./containers/weight_loss_surgeon_match";
import SpineSurgerySurgeonMatch from "./containers/spine_surgery/surgeon_match";
import WomensHealthSurgeonMatch from "./containers/womens_health/surgeon_match";
import SurgeonMatchJourneyFeedback from "./containers/surgeon_match_journey_feedback";
import WeightLossWebinar from "./containers/weight_loss_webinar";
import DrRussoAndDina from "./containers/webinars/april_28_2021";
import WebinarViewApril292021 from "./components/webinar_view/april_28_2021";
import DrSmithAndAshley from "./containers/webinars/june_29_2021";
import WebinarViewJune292021 from "./components/webinar_view/june_29_2021";
import WebinarViewSeptember212021 from "./components/webinar_view/september_21_2021";
import DrBernieHanna from "./containers/webinars/september_21_2021";
import WeightLossWebinarView from "./components/weight_loss_webinar_view";
import January182022Webinar from "./containers/webinars/january_18_2022";
import WebinarViewJanuary182022 from "./components/webinar_view/january_18_2022";
import ScrollToTop from "./components/scroll_to_top";
import SendPageView from "./components/send_page_view";
import Modal from "./components/modal";
import WebinarPopup from "./components/webinar_popup";
import UiKit from "./components/ui_kit";
import InsuranceCheckForm from "./containers/insurance_check_form";
import SurgeonMatchForm from "./containers/surgeon_match_form";
import ContactUs from "./containers/contact_us";
import ReportAnEvent from "./components/report_an_event";
import ForMentors from "./components/for_mentors";
import PressRoom from "./components/press_room";
import MetadataTags from "./components/metadata_tags";
import AcceptPrivacyModal from './components/accept_privacy_modal';
import ConversationRoom from "./containers/users/conversation_room";
import UsersSignUp from "./components/users_sign_up";
import UsersSignIn from "./components/users_sign_in";
import UsersPasswordRecover from "./components/users_password_recover";
import UsersPasswordReset from "./components/users_password_reset";
import MemberHome from "./components/member/home";
import { useCookies } from "react-cookie";
import Context from "./utils/context";
import Auth from "./utils/auth";
import Viewport from "./utils/viewport";
import ActionCable from "./utils/actioncable";
import ProfileStatus from "./utils/profile_status";
import FacebookConversionApi from "./utils/facebook_conversion_api";
import ForProviders from "./components/for_providers";
import IndustryPartners from "./components/industry_partners";
import ProviderHome from "./components/providers/home";
import ProviderPatients from "./components/providers/patients";
import ProviderPatientShow from "./components/providers/patient_show";
import ProviderAccountDetails from "./components/providers/account_details";
import AdminConversations from './components/admin/conversations';
import AdminConversationsShow from './components/admin/conversations/show';
import "./App.css";
import "./css/react-toastify.css";
import ProviderHeader from "./components/provider_header";
import WeGiveBack from "./components/we_give_back";
import GlobalPresence from "./components/global_presence/global_presence";
import ProductDiscovery from "./components/product_discovery_landing";
import CreatePost from "./components/posts/create_post";
import NotFoundPage from "./components/not_found_page";
import ReferralPartnerAgreement from "./components/terms/pip";
import ServiceAgreement from "./components/terms/service_agreement";
import Baa from "./components/terms/baa";
import Blogs from "./components/blogs";
import Article from "./components/blogs/article";
import MemberPlatformNavWrap from "./components/member_platform_nav_wrap";
import Settings from "./components/settings";
import { renderHeader, NewApp } from "./NewApp.js";
import Newsfeed from "./components/newsfeed";
import ProviderInsights from "./components/providers/insights";
import PurchasePage from "./components/transactions/purchase_page";
import styles from './index.module.css'

const auth = new Auth(gon.current_user || {});
const viewport = new Viewport();
const profileStatus = new ProfileStatus();
const actioncable = new ActionCable();
const facebookConversionApi = new FacebookConversionApi();

const PublicRoute = ({ component: Component, path, layout: Layout }) => (
  <Route
    path={path}
    render={(props) => (
      <Layout>
        <Component path={path} {...props} />
      </Layout>
    )}
  />
);

const PrivateRoute = ({ component: Component, auth, path, layout: Layout }) => {
  const returnTo = window.location.pathname;
  const pathname = `/member/login?return_to=${encodeURIComponent(returnTo)}`;

  return (
    <Route
      // exact
      path={path}
      render={(props) =>
        auth.isLoggedIn() ? (
          <Layout>
            <Component currentUser={auth.getCurrentUser()} {...props} />
          </Layout>
        ) : (
          <Redirect to={pathname} />
        )
      }
    />
  );
};

const PublicOnlyRoute = ({
  component: Component,
  auth,
  path,
  layout: Layout,
}) => {
  let redirectUrl = "/member/home";
  if (auth.isLoggedIn() && auth.getCurrentUser().current_profile_type === "SurgeonAdministrativeAssistant") {
    redirectUrl = "/provider/patients";
  }
  if (auth.isLoggedIn() && auth.getCurrentUser().is_nybg) {
    redirectUrl = "/provider/mentor-connect";
  }

  return (
    <Route
      exact
      path={path}
      render={(props) =>
        auth.isLoggedIn() ? (
          <Redirect to={{ pathname: redirectUrl }} />
        ) : (
          <Layout>
            <Component path={path} {...props} />
          </Layout>
        )
      }
    />
  );
}

const ProviderOnlyRoute = ({
  component: Component,
  auth,
  path,
  layout: Layout,
}) => {
  const returnTo = window.location.pathname;
  const pathname = `/member/login?return_to=${encodeURIComponent(returnTo)}`;

  return (
    <Route
      path={path}
      render={(props) =>
        auth.isLoggedIn() &&
        auth.getCurrentUser().current_profile_type ===
          "SurgeonAdministrativeAssistant" ? (
          <Layout>
            <Component currentUser={auth.getCurrentUser()} {...props} />
          </Layout>
        ) : (
          <Redirect to={pathname} />
        )
      }
    />
  );
};

const PublicLayout = (props) => (
  <>
    {renderHeader(auth)}
    {props.children}
    {gon.embedded ?
        <></>
      :
        <Footer
          fullFooter={window.location.pathname.includes('mentor-match') && window.location.pathname.includes('surgeon') ? false : true}
        />
    }
  </>
);

const PortalLayout = (props) => (
  <>
    <div
      style={{
        backgroundImage:
          "url(https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/registerbg.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="container-fluid sign-in-outer"
    >
      <Link to="/">
        <img
          className="signin-logo"
          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/whlogo.svg"
        />
      </Link>
      {props.children}
    </div>
  </>
);

const PlatformLayout = (props) => (
  <div className={styles.platform_layout}>
    {renderHeader(auth)}
    {props.children}
  </div>
);

const ABTestingLayout = (props) => (
  <>
    {renderHeader(auth)}
    {props.children}
    <Footer fullFooter={false} />
  </>
);

const getUTMParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get("utm_source"),
    utm_medium: params.get("utm_medium"),
    utm_campaign: params.get("utm_campaign"),
    utm_term: params.get("utm_content"),
    utm_content: params.get("utm_ad_content"),
  };
};

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["iglive", "utm_params"]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // Capture and store UTM parameters
    const utmParams = getUTMParams();

    if (utmParams.utm_source || utmParams.utm_medium || utmParams.utm_campaign) {
      // Store UTM parameters in a cookie if they exist
      setCookie("utm_params", JSON.stringify(utmParams), { path: "/", maxAge: 30 * 24 * 60 * 60 });
    }
  }, []);


  return (
    <Context.Provider
      value={{
        auth: auth,
        viewport: viewport,
        profileStatus: profileStatus,
        actioncable: actioncable,
        facebookConversionApi: facebookConversionApi,
      }}
    >
      <Router>
        <ScrollToTop />
        <SendPageView />
        <MetadataTags />
        <div className="App" id="topOfPage">
        <AcceptPrivacyModal/>
        <ToastContainer />
        {auth.isLoggedIn() &&
          <GlobalPresence/>
        }
        <Switch>
          {/* <PublicRoute exact path="/" component={Home} layout={PublicLayout} /> */}
          <PublicRoute exact path="/who-we-are" component={About} layout={PublicLayout} />
          <PublicRoute exact path="/we-give-back" component={WeGiveBack} layout={PublicLayout} />
          <PublicRoute exact path="/how-it-works" component={Process} layout={PublicLayout} />
          <PublicRoute exact path="/weight-loss" component={WeightLoss} layout={PublicLayout} />
          <PublicRoute exact path="/procedures/weight-loss" component={WeightLoss} layout={PublicLayout} />
          <PublicRoute exact path="/procedures/joint-replacement" component={JointReplacement} layout={PublicLayout} />
          <PublicRoute exact path="/weight-loss/surgeon-match" component={WeightLossSurgeonMatch} layout={PublicLayout} />
          <PublicRoute exact path="/spine-surgery/surgeon-match" component={SpineSurgerySurgeonMatch} layout={PublicLayout} />
          <PublicRoute exact path="/uterine-fibroids/surgeon-match" component={WomensHealthSurgeonMatch} layout={ABTestingLayout} />
          <PublicRoute exact path="/weight-loss/surgeon-match/journey-feedback" component={SurgeonMatchJourneyFeedback} layout={PublicLayout} />
          <PublicRoute exact path="/get-started" component={GetStartedV2} layout={PublicLayout} />
          <PublicRoute exact path="/get-started/patient-partner-match" component={GetStartedPatientPartnerMatch} layout={PublicLayout} />
          <PublicRoute exact path="/get-started/surgeon-match" component={GetStartedSurgeonMatch} layout={PublicLayout} />
          <PublicRoute exact path="/partners" component={Partners} layout={PublicLayout} />
          <PublicRoute exact path="/terms" component={Terms} layout={PublicLayout} />
          <PublicRoute exact path="/terms/pip" component={ReferralPartnerAgreement} layout={PublicLayout} />
          <PublicRoute exact path="/terms/service-agreement" component={ServiceAgreement} layout={PublicLayout} />
          <PublicRoute exact path="/terms/baa" component={Baa} layout={PublicLayout} />
          <PublicRoute exact path="/privacy" component={Privacy} layout={PublicLayout} />
          <PublicRoute exact path="/review-guidelines" component={ReviewGuidelines} layout={PublicLayout} />
          <PublicRoute exact path="/patient-partner-agreement" component={PatientPartnerAgreement} layout={PublicLayout} />
          <PublicRoute exact path={`/c/:id/patient-partner-survey`} component={PatientPartnerSurvey} layout={PublicLayout} />
          <PublicRoute exact path={`/surgeon/:id/partners`} component={SurgeonPartners} layout={PublicLayout} />
          <PublicRoute exact path={`/surgeon/:id/request-appointment`} component={SurgeonRequestAppointment} layout={PublicLayout} />
          <PublicRoute path={`/reactiv8/mentor-match`} component={SurgeonReferredMentorMatch} layout={PublicLayout} />
          <PublicRoute path={`/surgeon/:id/mentor-match`} component={SurgeonReferredMentorMatch} layout={PublicLayout} />
          <PublicRoute path={`/nybg/mentor-match`} component={SurgeonReferredMentorMatch} layout={PublicLayout} />
          <PublicRoute path={`/surgeon/:id`} component={SurgeonProfile} layout={PublicLayout} />
          <PublicRoute exact path="/weight-loss-webinar" component={WeightLossWebinar} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/april-28-2021" component={DrRussoAndDina} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/april-28-2021/view" component={WebinarViewApril292021} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/june-29-2021" component={DrSmithAndAshley} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/june-29-2021/view" component={WebinarViewJune292021} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/september-21-2021" component={DrBernieHanna} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/september-21-2021/view" component={WebinarViewSeptember212021} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/january-18-2022" component={January182022Webinar} layout={PublicLayout} />
          <PublicRoute exact path="/webinar/january-18-2022/view" component={WebinarViewJanuary182022} layout={PublicLayout} />
          <PublicRoute exact path="/ui-kit" component={UiKit} layout={PublicLayout} />
          <PublicRoute exact path="/weight-loss-webinar-view" component={WeightLossWebinarView} layout={PublicLayout} />
          <PublicRoute exact path="/insurance-check" component={InsuranceCheckForm} layout={PublicLayout} />
          <PublicRoute exact path="/surgeon-referral" component={SurgeonMatchForm} layout={PublicLayout} />
          <PublicRoute exact path="/all-surgeons" component={Surgeons} layout={PublicLayout} />
          <PublicRoute exact path="/press-room" component={PressRoom} layout={PublicLayout} />
          <PublicRoute exact path="/contact-us" component={ContactUs} layout={PublicLayout} />
          <PublicRoute exact path="/report-an-event" component={ReportAnEvent} layout={PublicLayout} />
          <PublicRoute exact path="/for-mentors" component={ForMentors} layout={PublicLayout} />
          <PublicRoute exact path="/blogs" component={Blogs} layout={PublicLayout} />
          <PublicRoute exact path="/blogs/:id" component={Article} layout={PublicLayout} />
          <PrivateRoute path={`/member/conversations/:conversation_id?`} auth={auth} component={ConversationRoom} layout={MemberPlatformNavWrap} />
          { /* <PrivateRoute exact path={`/member/home`} auth={auth} component={Newsfeed} layout={MemberPlatformNavWrap}/> */ }
          <PublicRoute exact path="/for-providers" component={ForProviders} layout={PublicLayout} />
          <PublicRoute exact path="/industry-partners" component={IndustryPartners} layout={PublicLayout} />
          <PublicRoute exact path="/simplify-your-surgery-experience/waitlist" component={ProductDiscovery} layout={ABTestingLayout} />
          {/* <PublicOnlyRoute exact path="/member/join" auth={auth} component={UsersSignUp} layout={PortalLayout} /> */}
          <PublicOnlyRoute exact path="/member/login" auth={auth} component={UsersSignIn} layout={PortalLayout} />
          <PublicRoute exact path="/member/forgot-password" component={UsersPasswordRecover} layout={PortalLayout} />
          <PublicRoute exact path="/member/change-password" component={UsersPasswordReset} layout={PortalLayout} />
          {/* <PrivateRoute exact path="/member/home" auth={auth} component={MemberHome} layout={PlatformLayout} /> */}
          <PrivateRoute exact path="/post/create" auth={auth} component={CreatePost} layout={PlatformLayout} />
          {/* <PublicRoute exact path={`/post/:id`} auth={auth} component={SinglePostPage} layout={PublicLayout}/> */}
          <PrivateRoute exact path={`/member/profile/settings`} auth={auth} component={Settings} layout={MemberPlatformNavWrap}/>
          <PrivateRoute exact path={`/member/settings/account`} auth={auth} component={Settings} layout={MemberPlatformNavWrap}/>
          <PrivateRoute exact path={`/member/settings`} auth={auth} component={Settings} layout={MemberPlatformNavWrap}/>
          <ProviderOnlyRoute exact path="/provider/account-details" auth={auth} component={ProviderAccountDetails} layout={PlatformLayout} />
          <ProviderOnlyRoute exact path="/provider/patients/:patientAssistantInfoId" auth={auth} component={ProviderPatientShow} layout={PlatformLayout} />
          <ProviderOnlyRoute path="/provider" auth={auth} component={ProviderHome} layout={PlatformLayout} />
          <ProviderOnlyRoute exact path="/provider/patients" auth={auth} component={ProviderPatients} layout={PlatformLayout} />
          <ProviderOnlyRoute exact path="/admin/conversations/:conversation_id" auth={auth} component={AdminConversationsShow} layout={PlatformLayout} />
          <ProviderOnlyRoute exact path="/admin/conversations" auth={auth} component={AdminConversations} layout={PlatformLayout} />
          <PublicRoute path="/member/checkout" auth={auth} component={PurchasePage} layout={PlatformLayout} />
          <Route path={[
            `/`,
            `/member/home`,
            `/post/:id`,
            `/member/join`,
            `/member/join/support`,
            `/member/join/support/*`,
            `/procedures/cosmetic`,
            `/procedures/spine`,
            `/member/profile/:id`,
            `/member/profile/:id/:view(post|about-me|health-profile)`,
            `/member/mentor-match`,
            `/procedures/spine/virtual-consultation`,
            `/procedures/cosmetic/virtual-consultation`,
            `/member/surgeon-mentor-matches/:id/spv`,
            `/procedures/uterine-fibroids/mentor-match`,
            `/procedures/uterine-fibroids`,
            `/procedures/uterine-fibroids2`,
            `/member/join/support/uterine-fibroids`,
            `/member/join/surgeon-match/uterine-fibroids`,
            `/doctor-connect/acl-reconstruction`,
            `/doctor-connect/acl-reconstruction/:id`,
            `/nybg/mentor-match`,
            `/surgeon-matches/:id`,
            '/better-is-possible',
            `/treatments/skx`,
            `/treatments/spv`,
          ]} component={NewApp} />
          <PublicRoute path="*" auth={auth} component={NotFoundPage} layout={PublicLayout} />
        </Switch>
        </div>
        {/* {
          showModal && (
            <Modal
              className="pp-modal wl-webinar-modal"
              content={(
                <WebinarPopup
                  headline="INSTAGRAM LIVE"
                  smallHeadline={"+ Giveaway"}
                  subheadline="RECIPE FOR SUCCESS WITH WLS"
                  middleContent={<p className='marg-bottom-10'><b> February 22nd <br/> 5 PM PST / 7 PM CST</b></p>}
                  showLogo={true}
                  para=""
                  actionContent={<a target="_blank" onClick={() => setShowModal(false)} href="https://sweepwidget.com/view/49439-xuclz86o"><button data-gtm-click-label="IGLIVE February 2022 Popup Sign Up" className="wb-cta">Register for event</button></a>}
                  image="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/popups/IG+Live+WL+Pop+Up.jpg"
                  leftClassnames={'text-center'}
                />
              )}
              onClose={() => {
                setShowModal(false)
              }}
            />
          )
        } */}
      </Router>
    </Context.Provider>
  );
}

export default App;

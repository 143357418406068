import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
    useTransition,
    useChain,
    animated,
    useSpringRef,
} from '@react-spring/web'
import Context from '../../utils/context';

export default function MobileNavItem({
    navItem,
    setNavOpen,
    pendoTag = ''
}) {
    const context = useContext(Context);
    let history = useHistory();
    const [open, setOpen] = useState(false);

    const dropDownAnimation = useSpringRef();
    const dropdown = useTransition(open ? navItem : [], {
        ref: dropDownAnimation,
        config: { mass: 1, tension: 500, friction: 30 },
        from: { opacity: 0, transform: 'translateY(-10px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' }
    });

    const navItemsAnimation = useSpringRef();
    const navItems = useTransition(open ? navItem.dropdown : [], {
        ref: navItemsAnimation,
        trail: open ? 100 / navItem.dropdown.length : 0,
        config: { mass: 1, tension: 1000, friction: 100 },
        from: { opacity: 0, transform: 'translateX(30px)' },
        enter: { opacity: 1, transform: 'translateX(0px)' }
    });

    useChain(open ? [dropDownAnimation, navItemsAnimation] : [navItemsAnimation, dropDownAnimation], [
        0,
        0.05
    ]);

    const mainLinkOnClick = (navItem) => () => {
      if (navItem.mainLink.onclick === 'logout') {
        context.auth.userLogOut();
      }
      else if (navItem.mainLink.onclick === 'stopImpersonating') {
        context.auth.stopImpersonating(() => {
          history.push('/member/home');
        }, () => {

        });
      }
    }

    return (
        <li data-pendo-tag={pendoTag} onClick={() => { navItem.dropdown ? setOpen(!open) : setNavOpen(false) }} className="nav-item">
            {(navItem.mainLink.title === 'Join for free') || navItem.mainLink.hr_top && <hr />}
            {navItem.mainLink.link === '' ? (
                <p data-gtm-click-label={`Nav ${navItem.mainLink.title}`} style={{ cursor: 'pointer' }} className="bold">{navItem.mainLink.title}</p>
            ) : (
                // Check if link contains 'mailto', or if it's an external link that starts with 'http'
                navItem.mainLink.link?.includes('mailto') ? (
                    <a className="nav-link" data-gtm-click-label={`Nav ${navItem.mainLink.title}`} href={navItem.mainLink.link}>
                        {navItem.mainLink.title}
                    </a>
                ) : (
                    navItem.mainLink.link?.startsWith('http') ? (
                        <a className="nav-link" href={navItem.mainLink.link} target="_blank" rel="noopener noreferrer" data-gtm-click-label={`Nav ${navItem.mainLink.title}`}>
                            {navItem.mainLink.title}
                            {navItem.mainLink.smallTitle && <p>{navItem.mainLink.smallTitle}</p>}
                        </a>
                    ) : (
                        <Link onClick={mainLinkOnClick(navItem)} className="nav-link" data-gtm-click-label={`Nav ${navItem.mainLink.title}`} to={navItem.mainLink.link} {...navItem.mainLink.props}>
                            {navItem.mainLink.title}
                            {navItem.mainLink.smallTitle && <p>{navItem.mainLink.smallTitle}</p>}
                        </Link>
                    )
                )
            )}
            {navItem.dropdown && (
                <div className="arrow-wrapper">
                    <svg className={`dropdown-arrow ${open ? 'active' : ''}`} width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.93627 10.9783C1.8408 11.0685 1.7285 11.1391 1.60577 11.1859C1.48304 11.2327 1.35229 11.2549 1.22099 11.2512C1.08969 11.2474 0.960401 11.2179 0.840516 11.1642C0.72063 11.1105 0.612491 11.0338 0.522273 10.9383C0.432054 10.8428 0.361524 10.7305 0.314709 10.6078C0.267893 10.4851 0.245709 10.3543 0.249423 10.223C0.253138 10.0917 0.282678 9.96244 0.336356 9.84255C0.390035 9.72267 0.466801 9.61453 0.562272 9.52431L9.56227 1.02431C9.74794 0.848773 9.99376 0.750966 10.2493 0.750966C10.5048 0.750966 10.7506 0.848773 10.9363 1.02431L19.9373 9.52431C20.0348 9.61393 20.1136 9.72205 20.169 9.84237C20.2245 9.9627 20.2554 10.0928 20.2601 10.2252C20.2648 10.3576 20.2432 10.4896 20.1964 10.6136C20.1497 10.7376 20.0788 10.851 19.9878 10.9473C19.8969 11.0436 19.7877 11.1209 19.6666 11.1747C19.5455 11.2284 19.415 11.2576 19.2825 11.2605C19.1501 11.2634 19.0184 11.2399 18.8951 11.1915C18.7718 11.143 18.6593 11.0706 18.5643 10.9783L10.2493 3.12631L1.93627 10.9783V10.9783Z" fill="#59B6BE" />
                    </svg>
                </div>
            )}
            {dropdown((style) => (
                <animated.div style={{ ...style }} className="dropdown">
                    {navItems((style, dropdownItem) => (
                        dropdownItem.link.startsWith('http') ? (
                            <a className="dropdown-parent-link" href={dropdownItem.link} target="_blank" rel="noopener noreferrer" data-gtm-click-label={`Nav ${dropdownItem.title}`} onClick={() => setNavOpen(false)}>
                                <animated.div style={{ ...style }} className="dd-item">
                                    <div className="dd-item-text">
                                        <p data-gtm-click-label={`Nav ${dropdownItem.title}`} style={{ marginBottom: '-3px' }} className="bold">
                                            {dropdownItem.title}
                                        </p>
                                    </div>
                                </animated.div>
                            </a>
                        ) : (
                            <Link className="dropdown-parent-link" to={dropdownItem.link} data-gtm-click-label={`Nav ${dropdownItem.title}`} onClick={() => setNavOpen(false)} {...dropdownItem.props}>
                                <animated.div style={{ ...style }} className="dd-item">
                                    <div className="dd-item-text">
                                        <p data-gtm-click-label={`Nav ${dropdownItem.title}`} style={{ marginBottom: '-3px' }} className="bold">
                                            {dropdownItem.title}
                                        </p>
                                    </div>
                                </animated.div>
                            </Link>
                        )
                    ))}
                </animated.div>
            ))}
        </li>
    )

}

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import FeaturedInMedia from '../featured_in_media'
import ImgLeftTextRightSimple from '../hero_sections/img_left_text_right_simple'
import ImgRightTextLeftSimple from '../hero_sections/img_right_text_left_simple'
import ImgRightTextLeft from '../hero_sections/img_right_text_left'
import TwoTestimonials from '../two_testimonials'
import styles from './index.module.css'
import SingleVideoCenteredWDecor from '../video_sections/single_video_centered_w_decor'
import WaveSeparator from '../ui_kit/utilities/wave_separator'
import JoinFooter from '../join_footer'
import Context from '../../utils/context';

export default function Home({
  updateAnswersAction
}) {
  const context = useContext(Context)
  const isMobile = context.viewport.getIsMobile();

  return (
    <div>
      <ImgRightTextLeft
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/hero_partners_2024_10_02.png'
        bg='#DDF7F9'
        headline='Find a mentor for your healthcare journey'
        para='Connect with a patient mentor who has firsthand experience to support you through your treatment journey.'
        marginTopPhotoMobile='30px'
      />
      <FeaturedInMedia
        images={[
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/forbes.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/abc.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/yahoo_a.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/nbc_a.jpg'
        ]}
      />
      <ImgLeftTextRightSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/patient_partner_connection.jpg'
        bg='#fff'
        headline="Connect with patients who've been where you are"
        para="Connect with patients who offer personal insights, share their treatment stories, and provide the support you're looking for. Your healthcare journey is easier with others by your side."
        actionContent={<></>}
        headlineType='h2'
      />
      <TwoTestimonials
        testimonials={[
          {
            text: "Joining the PatientPartner community was easily the highlight of my week. I feel like I've made new friends, and I'm more than confident that I'm ready for this treatment.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/Kate_avatar.jpg',
            name: 'Kate',
            city: 'Dallas, TX'
          },
          {
            text: "A community like PatientPartner is so important because sometimes you just don't know where to go. You need that community to lean on and trust.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/charles_avatar.jpg',
            name: 'Charles',
            city: 'San Diego, CA'
          },
        ]}
      />
      <ImgLeftTextRightSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/find-a-mentor-for-you-2024-10-02.png'
        bg='#fff'
        headline='Meet our network of mentors'
        para="Our trusted patient mentors are here for you. They've been through similar experiences and are ready to answer your questions, offer support, and guide you through each step of your journey. From diagnosis to treatment and beyond, your mentor will be there to help you every step of the way."
        headlineType='h2'
        actionContent={<Link to="/partners"><button data-gtm-click-label="Home Find my mentor" style={{ padding: '12px 20px' }}>Find my mentor</button></Link>}
      />
      <ImgRightTextLeftSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/how-it-works-2024-10-02.png'
        bg='#fff'
        headline='How it works'
        para='Speak privately with a PatientPartner Mentor, a verified recovered patient who has already been through the same procedure. They volunteer their time to answer any sensitive questions and guide you before or after your surgery. '
        headlineType='h2'
        middleContent={(
          <ul className={styles.unlist}>
            <li className={styles.list_item}>Take our quiz to find a mentor who's been through a similar journey, ensuring the perfect match for you!</li>
            <li className={styles.list_item}>Meet the mentor you've been matched with and send them a message to introduce yourself and ask questions.</li>
            <li className={styles.list_item}>Ask questions, gain insights from their experiences, and get support from someone dedicated to guiding you every step of the way.</li>
          </ul>
        )}
        actionContent={<Link to="/member/join/surgeon-match"><button className="liner" data-gtm-click-label="Home Find my mentor" style={{ padding: '12px 20px' }}>Connect with a Mentor now</button></Link>}
      />
      <ImgRightTextLeftSimple
        title="Industry Partners"
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/PatientPartner+Website+Images+(2).png'
        bg='#ddf7f9'
        headline='Patients sharing how your products changed their lives.'
        para=''
        headlineType='h2'
        imageStyling={isMobile ? {} : { marginBottom: '-80px' }}
        middleContent={(
          <ul className={styles.unlist}>
            <li className={styles.list_item}>Provide new patients with guidance from an experienced patient who's already familiar with your therapy.</li>
            <li className={styles.list_item}>Build your company and brand's reputation as the trusted patient leader for your disease state.</li>
            <li className={styles.list_item}>Gain actionable insights into real patient sentiment towards your treatments, allowing you to better understand your patient population.</li>
            <p className={styles.list_item}><b>Learn how this became the most successful patient marketing and services tool for our industry partners.</b></p>
          </ul>
        )}
        actionContent={
          <div>
            <a href="https://www.trypatientpartner.com" target="_blank" rel="noopener noreferrer">
              <button className="homepage-button" data-gtm-click-label="Home Industry Partners" style={isMobile ? {padding: '12px 20px', margin: '20px'} : {padding: '12px 20px', margin: '0 20px 0 20px'}}>
                Learn More
              </button>
            </a>
            <a href="https://calendly.com/georgekramb/30min" target="_blank" rel="noopener noreferrer">
              <button className="homepage-button" data-gtm-click-label="Book A Demo" style={isMobile ? { backgroundColor:'#314d69', padding: '12px 20px', margin: '20px' } : { backgroundColor:'#314d69', padding: '12px 20px', margin: '0 20px 0 20px' }}>
                Book A Demo
              </button>
            </a>
          </div>
        }
      />
      <SingleVideoCenteredWDecor
        title={"Find Your Support Or Mentor Today"}
        videoBg="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videomentorbg.jpg"
        videoLink="https://www.youtube.com/embed/t_6d-2PCcLI?si=AEj3HYWYUeqO9xDr"
        bgColor='#EFEFEF'
        para={"Get insights into your health condition and treatment options from peers who've already experienced it. Signing up is simple, and it's completely free."}
      />
      <WaveSeparator/>
      <JoinFooter
        updateAnswersAction={updateAnswersAction}
      />
    </div>
  )
}

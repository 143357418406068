import React, { useState } from 'react';
import { validatePassword } from '../../../../helpers';
import ErrorMessage from '../error_message'
import './index.css'

export default function PasswordInput({
  password,
  setPassword,
  label = "Password",
  requireConfirmation = false,
  skipPasswordValidation = false,
  embedded = false,
  light = false,
  medium = false,
  name="password",
  signUp=false,
  onError,
}) {
  const [currentPassword, setCurrentPassword] = useState(password || '');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [currentConfirmationPassword, setCurrentConfirmationPassword] = useState(password || '');
  const [showCurrentConfirmationPassword, setShowCurrentConfirmationPassword] = useState(false);
  const [error, setError] = useState('');

  let validCurrentPassword = skipPasswordValidation || validatePassword(currentPassword);
  let validCurrentConfirmationPassword = !requireConfirmation || (validCurrentPassword && currentPassword === currentConfirmationPassword);

  const currentPasswordOnBlur = () => {
    if (signUp) {
      if (currentPassword !== '' && !validCurrentPassword) {
        setError('Password must have 8 characters, include upper and lower case letters, numbers, and 1 special character ( / $ % @ ).');
        onError?.('Password must have 8 characters, include upper and lower case letters, numbers, and 1 special character ( / $ % @ ).');
      }
      else if (currentPassword !== '' && currentConfirmationPassword !== '' && !validCurrentConfirmationPassword) {
        setError('The passwords you entered do not match');
        onError?.('The passwords you entered do not match');
      }
      else {
        setError('');
        onError?.('');
      }
    }
  };

  const currentPasswordOnChange = (value) => {
    setCurrentPassword(value);

    let validCurrentPassword = skipPasswordValidation || validatePassword(value);
    let validCurrentConfirmationPassword = !requireConfirmation || (validCurrentPassword && value === currentConfirmationPassword);

    if (value !== '' && validCurrentPassword && validCurrentConfirmationPassword) {
      setPassword(value);
    }
    else {
      setPassword(value);
    }

  }

  const currentConfirmationPasswordOnChange = (value) => {
    setCurrentConfirmationPassword(value);

    let validCurrentPassword = skipPasswordValidation || validatePassword(currentPassword);
    let validCurrentConfirmationPassword = !requireConfirmation || (validCurrentPassword && currentPassword === value);
    if (signUp) {
      if (value !== '' && validCurrentPassword && validCurrentConfirmationPassword) {
        setPassword(value);
      }
      else {
        setPassword(value);
      }
    }
  }

  return (
    <>
      <div style={embedded ? {maxWidth: '100%'} : {}} className={`material-input width-100 ${light ? 'light' : (medium ? 'medium' : '')}`}>
        <input
          autoComplete="current-password"
          type={showCurrentPassword ? "text" : "password"}
          name={name}
          onBlur={currentPasswordOnBlur}
          value={currentPassword}
          onChange={(event) => currentPasswordOnChange(event.target.value)}
          required
        />
        <span className="bar"></span>
        <label>{label}</label>
        <div onClick={() => setShowCurrentPassword(!showCurrentPassword)} className="toggle-password">
          {showCurrentPassword &&
            <svg style={{ opacity: '.5' }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.8698 6.962C20.3438 7.582 20.3438 8.419 19.8698 9.038C18.3768 10.987 14.7948 15 10.6128 15C6.4308 15 2.8488 10.987 1.3558 9.038C1.12519 8.74113 1 8.37592 1 8C1 7.62408 1.12519 7.25887 1.3558 6.962C2.8488 5.013 6.4308 1 10.6128 1C14.7948 1 18.3768 5.013 19.8698 6.962V6.962Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M10.6128 11C12.2696 11 13.6128 9.65685 13.6128 8C13.6128 6.34315 12.2696 5 10.6128 5C8.95594 5 7.61279 6.34315 7.61279 8C7.61279 9.65685 8.95594 11 10.6128 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
          }
          {!showCurrentPassword &&
            <svg style={{ opacity: '.5' }} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.48593 14.129C3.64093 12.819 2.18093 11.115 1.35593 10.039C1.12523 9.74198 1 9.37659 1 9.0005C1 8.62441 1.12523 8.25902 1.35593 7.962C2.84893 6.013 6.43093 2 10.6129 2C12.4889 2 14.2429 2.807 15.7429 3.874" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12.7429 6.887C12.4652 6.60467 12.1343 6.38011 11.7694 6.22628C11.4045 6.07246 11.0127 5.99241 10.6167 5.99075C10.2207 5.98909 9.82825 6.06586 9.46205 6.21662C9.09585 6.36738 8.76312 6.58916 8.48306 6.86915C8.20299 7.14915 7.98114 7.48182 7.83029 7.84799C7.67944 8.21416 7.60258 8.60657 7.60415 9.00258C7.60572 9.3986 7.68567 9.79039 7.83941 10.1554C7.99315 10.5203 8.21763 10.8512 8.4999 11.129" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M2.61292 17L18.6129 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M8.61292 15.704C9.26212 15.8972 9.93557 15.9969 10.6129 16C14.7949 16 18.3769 11.987 19.8699 10.038C20.1006 9.74071 20.2256 9.37509 20.2255 8.99883C20.2253 8.62256 20.0998 8.25707 19.8689 7.96C19.3442 7.27549 18.7813 6.62112 18.1829 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
          }
        </div>
      </div>
      {
        requireConfirmation &&
        <div className={`material-input width-100 ${light ? 'light' : medium ? 'medium' : ''}`}>
          <input
            autoComplete="current-password-confirmation"
            type={showCurrentConfirmationPassword ? "text" : "password"}
            name="confirm-password"
            onBlur={currentPasswordOnBlur}
            value={currentConfirmationPassword}
            onChange={(event) => currentConfirmationPasswordOnChange(event.target.value)}
            required
          />
          <span className="bar"></span>
          <label>Confirm {label}</label>
          <div onClick={() => setShowCurrentConfirmationPassword(!showCurrentConfirmationPassword)} className="toggle-password">
            {showCurrentConfirmationPassword &&
              <svg style={{ opacity: '.5' }} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.8698 6.962C20.3438 7.582 20.3438 8.419 19.8698 9.038C18.3768 10.987 14.7948 15 10.6128 15C6.4308 15 2.8488 10.987 1.3558 9.038C1.12519 8.74113 1 8.37592 1 8C1 7.62408 1.12519 7.25887 1.3558 6.962C2.8488 5.013 6.4308 1 10.6128 1C14.7948 1 18.3768 5.013 19.8698 6.962V6.962Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M10.6128 11C12.2696 11 13.6128 9.65685 13.6128 8C13.6128 6.34315 12.2696 5 10.6128 5C8.95594 5 7.61279 6.34315 7.61279 8C7.61279 9.65685 8.95594 11 10.6128 11Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
            }
            {!showCurrentConfirmationPassword &&
              <svg style={{ opacity: '.5' }} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.48593 14.129C3.64093 12.819 2.18093 11.115 1.35593 10.039C1.12523 9.74198 1 9.37659 1 9.0005C1 8.62441 1.12523 8.25902 1.35593 7.962C2.84893 6.013 6.43093 2 10.6129 2C12.4889 2 14.2429 2.807 15.7429 3.874" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M12.7429 6.887C12.4652 6.60467 12.1343 6.38011 11.7694 6.22628C11.4045 6.07246 11.0127 5.99241 10.6167 5.99075C10.2207 5.98909 9.82825 6.06586 9.46205 6.21662C9.09585 6.36738 8.76312 6.58916 8.48306 6.86915C8.20299 7.14915 7.98114 7.48182 7.83029 7.84799C7.67944 8.21416 7.60258 8.60657 7.60415 9.00258C7.60572 9.3986 7.68567 9.79039 7.83941 10.1554C7.99315 10.5203 8.21763 10.8512 8.4999 11.129" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M2.61292 17L18.6129 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M8.61292 15.704C9.26212 15.8972 9.93557 15.9969 10.6129 16C14.7949 16 18.3769 11.987 19.8699 10.038C20.1006 9.74071 20.2256 9.37509 20.2255 8.99883C20.2253 8.62256 20.0998 8.25707 19.8689 7.96C19.3442 7.27549 18.7813 6.62112 18.1829 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
            }
          </div>
        </div>
      }
      {error &&
        <ErrorMessage
          error={error}
        />
      }
    </>
  );
}
